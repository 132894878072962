import React from 'react';
import styled from 'styled-components';
import { Page, SubTitle, PrimaryButton, PostText } from '../components';
import { Config } from '../config/config';
import { YoutubeVideo } from './about';

const AboutBurgessSection = styled.section`
	background: #fff;
	padding-top: 30px;
	padding-bottom: 60px;
	margin-top: 30px;
`;

const StyleDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;

const StyleImageDiv = styled.div`
  padding: 15px;
  width: 50%;
  img {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 800px) {
    width: auto;
  }
`;

const StyleTextDiv = styled.div`
  padding: 40px 30px;
  width: 50%;
  h3 {
    font-size: 21px;
    color: #404d5F;
    text-transform: uppercase;
    line-height: 36px;
    margin-top: 0;
    margin-bottom: 25px;
  }
  p {
    color: #333333;
    font-size: 16px;
    margin-top: 0;
    line-height: 27px;
  }

  @media only screen and (max-width: 800px) {
    width: auto;
    text-align: center;
    h3 {
      text-align: center;
    }
    p {
      text-align: justify;
    }
  }
`;

const FacilitySection = styled.section`
	  width: auto;
    background: #F9D93E;
    text-align: center;
    padding: 50px 20px;
    @media only screen and (max-width: 800px) {
      padding: 50px 0;
    }
`;

const TestimonialsSection = styled.section`
  width: 100%;
  text-align: center;
  padding: 1px 0 50px;
  @media(max-width: 864px) {
    padding: 0;
  }
`;

const TestimonialsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Testimonial = styled.div`
   width: 85%;
   margin: 0 auto;
`;
 
export const Facility = () => (
    <FacilitySection>
      <YoutubeVideo url="https://youtu.be/yQE12o30YLI" />
    </FacilitySection>
);

export const Testimonials = () => (
  <TestimonialsSection>
    <SubTitle title={Config["home"]["testiminal_block_title"]} subtitle={Config["home"]["testiminal_block_subtitle"]} />
    <TestimonialsDiv>
      {[1,2,3,4,5].map((number) => {
        return (
          <Testimonial>
            <PostText name={Config["home"][`testimonial_block_user${number}`]}>{Config["home"][`testiminal_block_post${number}`]}</PostText>
          </Testimonial>
        );
      })}
    </TestimonialsDiv>
  </TestimonialsSection>
)

export const Burgess = () => {
  return (
    <AboutBurgessSection>
        <SubTitle title={Config["home"]["burge_block_title"]} subtitle={Config["home"]["burge_block_subtitle"]} subcolor="white" />
        <br />
        <Page bg="white">
          <StyleDiv>
            <StyleImageDiv>
              <img src={Config["home"]["burge_block_image"]} alt={Config["home"]["burge_block_title"]} />
            </StyleImageDiv>
            <StyleTextDiv>
              <p>{Config["home"]["burge_block_content"]}</p>
              <br />
              <PrimaryButton link="#this">{Config["home"]["burge_block_button"]}</PrimaryButton>
            </StyleTextDiv>
          </StyleDiv>
        </Page>
		</AboutBurgessSection>
  );
}
