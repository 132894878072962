export const Config = {
    "home":{
        "website_name": "Valley Hi",
        "logo_image_path": "/assets/images/logo_main.jpg",
        "tab_block_title": "Valley Hi Programs",
        "tab_block_subtitle": "GET WELL SOON",

        "tab_block1_title": "Respiratory Programming",
        "tab_block2_title": "Cardiac Programming",
        "tab_block3_title": "Pulmonary Programming",
        "tab_block4_title": "Physiatry Services",
        "tab_block5_title": "Occupational Therapy",
        "tab_block6_title": "Restorative Programming",

        "tab_block1_content": "Valley Hi offers Respiratory Programming to those residents that may be utilizing oxygen therapy or have conditions affecting the cardiopulmonary system.  Residents with diagnosis like asthma, emphysema, pneumonia and cardiovascular disorders can be seen in this program.",
        "tab_block2_content": "Valley Hi offers transitional cardiology that bridges the gap between hospitals and skilled nursing facilities.  Already have a Cardiologist you’ve been seeing?   That’s o.k.  The team respects all existing relationships with outside providers.",
        "tab_block3_content": "Valley Hi offers transitional pulmonology services to those with conditions like COPD, Asthma, Edema of the Long, Influenza, Hyper/Hypotention, Pneumonia, Shortness of breath, and more.  Early intervention and treatment is discussed with our Pulmonology team.",
        "tab_block4_content": "Rehabilitation for our short term residents is so important to us so we added a physiatrist to our team.  A physiatrist is a medical doctor that specializes in physical medicine and rehabilitation.  They treat patients with pain and function-limiting muscoskeletal disorders.  They can prescribe pain injections and help reduce pain in the body so the patient can participate in rehab longer, getting them back home faster.",
        "tab_block5_content": "Valley Hi provides excellent rehabilitation services for both long-term and short-term residents.  Our contracted partner has state of the art equipment to help every resident reach their individualized goals and when appropriate, to return to the community safely.",
        "tab_block6_content": "Restorative Programming focuses on preventing further decline in the residents physical functioning.  The goal of this program is to restore the residents previous level of functioning and improve quality of life.",

        "tab_block1_ico": "/assets/images/ico_1.png",
        "tab_block2_ico": "/assets/images/ico_2.png",
        "tab_block3_ico": "/assets/images/ico_3.png",
        "tab_block4_ico": "/assets/images/ico_4.png",
        "tab_block5_ico": "/assets/images/ico_5.png",

        "tab_block1_image": "/assets/images/tab1.png",
        "tab_block2_image": "/assets/images/tab2.png",
        "tab_block3_image": "/assets/images/tab3.png",
        "tab_block4_image": "/assets/images/tab4.png",
        "tab_block5_image": "/assets/images/tab5.png",
        "tab_block6_image": "/assets/images/tab5.png",

        "burge_block_title": "About Valley Hi",
        "burge_block_subtitle": "GENERAL INFORMATION",
        "burge_block_image": "/assets/images/Valley Hi Picture 1.JPG",
        "burge_block_header": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut la\"",
        "burge_block_content": "Valley Hi provides 24-hour skilled nursing care and offers unique and highly specialized programming through partnerships with local medical specialists including pulmonology, respiratory, cardiac, and physiatry to compliment the restorative nursing and rehabilitation services provided on site.",
        "burge_block_button": "Learn More",
        "facility_block_button": "TAKE A VIRTUAL TOUR OF OUR FACILITY",

        "testiminal_block_title": "OUR TESTIMONIALS",
        "testiminal_block_subtitle": "What People Say",

        "testimonial_block_user1": "J.M",
        "testimonial_block_user2": "D.R",
        "testimonial_block_user3": "R.H",
        "testimonial_block_user4": "T.D",
        "testimonial_block_user5": "J.G",
        "testiminal_block_post1": "Valley Hi has phenomenal help. Everyone took such great care of me. If you ever have a concern bringing your loved one to Valley Hi, don’t hesitate because you will only get the best!",
        "testiminal_block_post2": "Valley Hi is a great place with an excellent reputation.  The staff is caring and courteous",
        "testiminal_block_post3": "My stay here was nice.  It’s my third time here and I enjoy the conversation with the nice people here. It’s fun, I liked the new suites. It was clean and the food was so good I think I gained 10lbs! Therapy was fantastic and helpful.",
        "testiminal_block_post4": "I was very pleased with this place.  The therapy was terrific and gave me a nice balance.  They were very understanding and anticipated my needs.  The staff is kind and understanding and was very attentive. I would be happy to return here.",
        "testiminal_block_post5": " The food, although too much, was good. It was clean, and staff worked hard to my needs.  Although therapy was hard, I worked, and they helped me get better to go home.  I would return if I needed to.",

        "schedule_tour_button": "SCHEDULE A TOUR",

        "footer_copyright_text": "© Valley Hi 2021"
     },
     "about": {
        "right_sidebar_title": "PROGRAMS AND SERVICES",
        "right_sidebar_menu1": "Left Sidebar Menu1",
        "right_sidebar_link1": "#this",
        "right_sidebar_menu2": "Left Sidebar Menu2",
        "right_sidebar_link2": "#this",
        "right_sidebar_menu3": "Left Sidebar Menu3",
        "right_sidebar_link3": "#this",
        "right_sidebar_menu4": "Left Sidebar Menu4",
        "right_sidebar_link4": "#this",
        "right_sidebar_menu5": "Left Sidebar Menu5",
        "right_sidebar_link5": "#this",
        "right_sidebar_menu6": "Left Sidebar Menu6",
        "right_sidebar_link6": "#this",
         "core": "At Valley Hi, we provide a wide range of highly skilled and personalized services; including long-term placement, short-term rehabilitation programming, Hospice services, and respite care.  We provide personalized care for those in need of permanent placement or need help getting back on their feet and back home again following an acute medical event. Valley Hi provides 24-hour skilled nursing care and offers unique and highly specialized programming though partnerships with local medical specialists including pulmonology, respiratory, cardiac, and physiatry to compliment the restorative nursing and rehabilitation services provided on site. Valley Hi participates in the Illinois Managed Medicaid Program, Medicare Program and is in network with a wide range of insurance providers.",
         "about_block_title": "Valley Hi",
         "about_block_subtitle": "WHO WE ARE",
         "about_block_image": "/assets/images/title.gif",

         "about_block_content1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas sed tempus urna et pharetra pharetra massa.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas sed tempus urna et pharetra pharetra massa.",
         "about_block_content2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas sed tempus urna et pharetra pharetra massa.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas sed tempus urna et pharetra pharetra massa.",

         "about_experience_title": "OUR EXPERIENCED TEAM",
         "about_experience_content": "Lorem test content1. This is test content for landing page. I'm a post text sub component.",

         "about_experience_bottom_title": "BOTTOM TITLE",
         "about_experience_bottom_subtitle": "BOTTOM SUB TITLE",

         "about_award_title": "Awards",
         "about_award_logo1": "/assets/images/acha_bronze.png",
         "about_award_alt1": "ACHA Bronze Award",
         "about_award_logo2": "/assets/images/silver_2023.png",
         "about_award_alt2": "ACHA Silver Award",
         "about_award_logo3": "/assets/images/best_fox.jpg",
         "about_award_alt3": "Best Fox Award",
         "about_award_logo4": "/assets/images/2021_fox.jpg",
         "about_award_alt4": "Best Fox Award",
         "about_award_logo5": "/assets/images/2022_fox.jpg",
         "about_award_alt5": "Best Fox Award",
         "about_award_logo6": "/assets/images/2023_fox.jpg",
         "about_award_alt6": "Best Fox Award",
         "about_award_logo7": "/assets/images/top_workplaces.jpg",
         "about_award_alt7": "Top Workplaces Award",
         "about_award_logo8": "/assets/images/workplaces_2021.jpg",
         "about_award_alt8": "Top Workplaces Award",
         "about_award_logo9": "/assets/images/workplaces_2022.jpg",
         "about_award_alt9": "Top Workplaces Award",
         "about_award_logo10": "/assets/images/workplaces_2023.jpg",
         "about_award_alt10": "Top Workplaces Award",

         // Extras
         "medicare_1": "/assets/images/Medicare suite 1.JPG",
         "medicare_2": "/assets/images/Medicare suite 2.JPG",
         "shower_room_1": "/assets/images/shower_room_1.jpg",
         "shower_room_2": "/assets/images/shower_room_2.jpg",
         "shower_room_3": "/assets/images/shower_room_3.jpg",

         "medicare_1_alt": "Medicare Room Bed",
         "medicare_2_alt": "Media Room Common Area",

         "shower_room_1_alt": "Shower Room Tub",
         "shower_room_2_alt": "Shower Room Showers",
         "shower_room_2_alt": "Shower Room Vanity",

         "therapy_room_1": "/assets/images/therapy/therapy_1.jpg",
         "therapy_room_2": "/assets/images/therapy/therapy_2.jpg",
         "therapy_room_3": "/assets/images/therapy/therapy_3.jpg",
         "therapy_room_4": "/assets/images/therapy/therapy_4.jpg",
         "therapy_room_5": "/assets/images/therapy/therapy_5.jpg",
         "therapy_room_6": "/assets/images/therapy/therapy_6.jpg",






     },
     "services": {
         "services_page_title": "Services",
         "sections": [
             {
                "name": "Respiratory Programming",
                "content": "Valley Hi has partnered with Integrated Respiratory Therapy to create a Pulmonary Center of Excellence. Respiratory therapy services are coordinated by a Respiratory Therapist who is onsite at least one two days per week and include CPAP / BiPAP, oxygen therapy, on site sleep studies, and more.  The Respiratory Therapist is part of our Interdisciplinary Team and has oversight by a Pulmonologist.   In 2012 over 1 million COPD patients were admitted to US Hospitals for acute respiratory exacerbation. Additionally, hospital readmissions for COPD within 30 days occur in 23% of the cases(US National Average). Strong medical evidence supports and Integrated’s outcomes support that a comprehensive multifaceted intervention involving proper identification and designation of patients, and a RT-Led respiratory care provider and care plan management leads to significant reduction in re-hospitalization."
             },
             {
                "name": "Cardiac Programming",
                "content": "Valley Hi partnered with Alteas Health and Dr. Joal Okner to provide individualized cardiac programming including on site ECG, Telemetry, and Telemedicine to improve quality of care, reduce cardiac complications and decrease hospitalizations. Our cardiologist team works closely with our respiratory therapist and pulmonology team to help residents improve cardiac functioning.  Residents are seen by an NP at least once a week."
             },
             {
                "name": "Pulmonary Programming",
                "content": "Valley Hi has partnered with Alteas Health and Dr. Jeremy Berg to provide individualized pulmonary programming including reduced pulmonary complications, decreased pulmonary readmissions, early intervention and treatment and improved functional capacity in our residents. Our pulmonology team works closely with our respiratory therapist and cardiology team to help residents improve pulmonary health."
             },
             {
                "name": "Physiatry",
                "content": "Valley Hi has partnered with Integrated Rehab Consultants and Dr. Sebastian Tobon to provide individualized physiatry programming to enhance the rehab program and improve results. Dr. Tobon works closely with Valley Hi staff and therapists to provide increased clinical documentation along with expert levels of post-acute rehab care. They provide a clinical-based solution to address issues that most affect facilities: re-admission rates, patient outcomes, patient satisfaction, and staff training."
             },
             {
                "name": "Physical and Occupational Therapy",
                "content": "Valley Hi has partnered with Consunus Health Care to provide physical, occupational and speech therapy programming.  Consonus uses specialized techniques and equipment like Vital Stim, ACP and Virtual Reality.  They offer individualized programming like cognitive disabilities, stand strong and focus on chronic conditions.  The Consonus team works well with Valley Hi staff and are involved with treatment from the beginning to the end."
             },
             {
                "name": "Restorative Programming",
                "content": "Valley Hi has certified restorative aides that work closely with residents needing this therapy.  The program is overseen by our Rehab Director.  In small groups they work with equipment such as Therabands, hand helpers, wrist/leg weights, close pins, the arm bike and pegs on an incline.   Other restorative programs may include bowel and bladder retraining, Passive/Active Range of Motion, Ambulation/Bed Mobility and Transfer Training. "
             },
         ]
     },
     "news": {
         "news_page_title": "NEWS AND EVENTS",
         "news_post_title": "ANOTHER TECHNOLOGY SOON-TO-BE LEVERAGED BY CLINICIANS IS THE 790 SIP INTEGRAT...",
         "news_post_link": "#this",
         "news_post_content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas sed tempus urna et pharetra pharetra massa.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas sed tempus urna et pharetra pharetra massa.",
     },
     "contact": {
         "contact_page_title": "CONTACT INFORMATION",
         "contact_block_title": "We'd love to hear from you!",
         "contact_block_subtitle": "Contact us by Phone, Online or by Mail.",
         "contact_phone_number": "815-338-0312",
         "contact_fax_number": "815-338-0458",
         "contact_email": "valleyhi@mchenrycountyil.gov"
     }
 };

 export const COLORS = {
     White: "#FFFFFF",
     Light_Gray: "#EFEFEF",
     Light_Black: "#333333",
     Brown: "#F9D93E",
     Light_Brown: "#EBB375",
     Green: "#85C35E",
 }
