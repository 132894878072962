import React from 'react';
import styled from 'styled-components';
import { Page, Clear } from '../components/common';
import { NavBar } from '../components/navBar';
import { Config } from '../config/config';

const HeaderStyle = styled.div`
	font-family: 'Arial', sans-serif;
	width: 100%;
	background: white;
	padding: 16px 0;
	height: auto;
	min-height: 100px;
	position: relative;
	.logo {
		position: absolute;
		width: 150px;
		height: auto;
		top: 15px;
		left: 15px;
		img {
			width: 250px;
			height: 100%;
			float: left;
		}
	}
`;

export const Header = () => {
	return (
		<HeaderStyle>
			<Page>
				<a href="/" title={Config["home"]["website_name"]} className="logo">
					<img src={Config["home"]["logo_image_path"]} alt="Valley Hi Nursing & Rehabilitation" />
				</a>
				<NavBar />
				<Clear />
			</Page>
		</HeaderStyle>
	);

}
