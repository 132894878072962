import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
	padding: 16px;
	box-shadow: 1px -1px #F0F0F0;
	border-radius: 16px;
`;

export const HomePageServiceCard = () => {

	return (
		<Card>
			<h4>Service</h4>
			<p>Content</p>
		</Card>
	);

}