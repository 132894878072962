import React, {useState} from "react";
import styled from "styled-components";
import { SubTitle, PrimaryButton, Clear } from '../components';
import { Config, COLORS } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLungs, faHeartbeat, faBone, faHandHoldingMedical, faUserNurse } from '@fortawesome/free-solid-svg-icons';
const CatDiv = styled.div`
  margin-top: 27px;
  scroll-behavior: smooth;
`;
const StyledUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;

  @media only screen and (max-width: 800px) {
      display: block;
  }
`;

const StyledLi = styled.li`
  text-transform: uppercase;
  font-size: 10px;
  width: 20%;
  background: rgba(255,255,255,0.4);
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 800px) {
		float: left;
    width: 33.33%;
    &.type--2 {
      width: 50%;
    }
	}
  
  &:first-child::before, &::after {
    content: '';
    position: absolute;
    top: 20%;
    height: 60%;
    width: 1px;
    background-color: #EEEEEE;
  }
  &:first-child::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  .active {
    // background: #efa507;
    &::after {
      content: '';
      position: absolute;
      left: calc(50% - 10px);
      top: 100%;
      width: 0;
      height: 0;
      border: solid transparent;
      border-width: 10px;
      border-top-color: black;
      pointer-events: none;
    }
  }
`;

const StyledA = styled.a`
  padding: 20px 40px;
  z-index: 10;
  position: relative;
  line-height: 15px;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  font-size: 1.3em;
  // margin: 5px 0 0 0;
  // display: block;
  // text-align: center;
  // text-transform: uppercase;
  // font-size: 10px;
  // color: #333;
  // padding: 20px 40px;
  // text-decoration: none;
  // font-size: 1.3em;
  // line-height: 20px;
  // position: relative;
  // z-index: 10;
`;

const StyledSection = styled.section`
  max-width: 1200px;
  text-align: left;
  clear: both;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  display: none;
  transition: all 0.3s ease-in-out;
  margin-top: -285px; 
  padding-top: 310px;
  &.active {
    display: flex;
  }

  @media only screen and (max-width: 800px) {
    margin-top: 0;
    padding-top: 20px;
    &.active {
      display: block;
    }
    div {
      float: none;
      width: 100%;
      padding-left: 0;
    }
  }
`;

const StyledDiv = styled.div`
  width: 50%;
`;

const StyledImage = styled.div`
  position: relative;
  .t_service {
    position: absolute;
    right: 0;
    bottom: 80px;
    display: block;
    padding: 10px 20px;
    width: auto;
    font-size: 20px;
    font-weight: bold;
    background: ${prop => prop.color ? prop.color : '#43B9F6'};
    color: #000;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const StyledText = styled.div`
  padding-left: 30px;
  padding-top: 20px;
  p {
    color: #333333;
    font-size: 16px;
    margin-top: 20px;
    line-height: 27px;
  }
`;


const SubCategory = ({id, fid, fa, active, onClickHandler, children}) => {  

  const faStyle = {
    color: COLORS.Green,
    fontSize: "1.5rem",
    margin: "0 0 15px 0"
  };

  return (
    <StyledA id={id} href={fid} className={active ? 'active': null} onClick={() => onClickHandler(id)}>
      <FontAwesomeIcon icon={fa} style={faStyle} />
      {/* <img src={img} alt={children}></img><br /> */}
      {children}
    </StyledA>
  );
}

const CatContent = ({id, title, img, color, active, children, link }) => {
  console.log("Link: ", link);
  return (
    <StyledSection id={id} className={active ? 'active': null}>
      <StyledDiv>
        <StyledImage color={color}>
          <img src={img} alt={title} />
          <div className="t_service">TRUSTED SERVICES</div>
        </StyledImage>
      </StyledDiv>
      <StyledDiv>
        <StyledText>
          <SubTitle title={title} align="left"/>
          <p>{children}</p>
          <br />
          <PrimaryButton link={`/service/${link}`}>READ MORE</PrimaryButton>
        </StyledText>
      </StyledDiv>
    </StyledSection>
  );
}

export const Category = () => {
  const [currentActiveCat, setCurrentActiveCat] = useState('subcat1');

  const clickHandler = (id) => {
    setCurrentActiveCat(id);
  }

    return (
      <CatDiv>
        <StyledUl>
          <StyledLi>
            <SubCategory link="0" id="subcat1" fid="#category-1" fa={faLungs} active={currentActiveCat === 'subcat1'} onClickHandler={clickHandler}>{Config["home"]["tab_block1_title"]}</SubCategory>
            {/* <SubCategory id="subcat1" fid="#category-1" img={Config["home"]["tab_block1_ico"]} active={currentActiveCat === 'subcat1'} onClickHandler={clickHandler}>{Config["home"]["tab_block1_title"]}</SubCategory> */}
          </StyledLi>
          <StyledLi>
            <SubCategory link="1" id="subcat2" fid="#category-2" fa={faHeartbeat} active={currentActiveCat === 'subcat2'} onClickHandler={clickHandler}>{Config["home"]["tab_block2_title"]}</SubCategory>
          </StyledLi>
          <StyledLi>
            <SubCategory link="2" id="subcat3" fid="#category-3" fa={faLungs} active={currentActiveCat === 'subcat3'} onClickHandler={clickHandler}>{Config["home"]["tab_block3_title"]}</SubCategory>
          </StyledLi>
          <StyledLi className="type--2">
            <SubCategory link="3" id="subcat4" fid="#category-4" fa={faBone} active={currentActiveCat === 'subcat4'} onClickHandler={clickHandler}>{Config["home"]["tab_block4_title"]}</SubCategory>
          </StyledLi>
          <StyledLi className="type--2">
            <SubCategory link="4" id="subcat5" fid="#category-5" fa={faHandHoldingMedical} active={currentActiveCat === 'subcat5'} onClickHandler={clickHandler}>{Config["home"]["tab_block5_title"]}</SubCategory>
          </StyledLi>
          <StyledLi className="type--2">
            <SubCategory link="5" id="subcat6" fid="#category-6" fa={faUserNurse} active={currentActiveCat === 'subcat6'} onClickHandler={clickHandler}>{Config["home"]["tab_block6_title"]}</SubCategory>
          </StyledLi>
        </StyledUl>
        <Clear />
        <CatContent link="0"id="category-1" title={Config["home"]["tab_block1_title"]} img={Config["home"]["tab_block1_image"]} color="#43B9F6" active={currentActiveCat === 'subcat1'}>
          {Config["home"]["tab_block1_content"]}
        </CatContent>
        <CatContent link="1"id="category-2" title={Config["home"]["tab_block2_title"]} img={Config["home"]["tab_block2_image"]} color="#43B9F6" active={currentActiveCat === 'subcat2'}>
          {Config["home"]["tab_block2_content"]}
        </CatContent>
        <CatContent ink="2"id="category-3" title={Config["home"]["tab_block3_title"]} img={Config["home"]["tab_block3_image"]} color="#43B9F6" active={currentActiveCat === 'subcat3'}>
          {Config["home"]["tab_block3_content"]}
        </CatContent>
        <CatContent link="3"id="category-4" title={Config["home"]["tab_block4_title"]} img={Config["home"]["tab_block4_image"]} color="#43B9F6" active={currentActiveCat === 'subcat4'}>
          {Config["home"]["tab_block4_content"]}
        </CatContent>
        <CatContent link="4"id="category-5" title={Config["home"]["tab_block5_title"]} img={Config["home"]["tab_block5_image"]} color="#43B9F6" active={currentActiveCat === 'subcat5'}>
          {Config["home"]["tab_block5_content"]}
        </CatContent>
        <CatContent link="5"id="category-6" title={Config["home"]["tab_block6_title"]} img={Config["home"]["tab_block6_image"]} color="#43B9F6" active={currentActiveCat === 'subcat6'}>
          {Config["home"]["tab_block6_content"]}
        </CatContent>
      </CatDiv>
	);
}