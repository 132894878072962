import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Clear, Page } from '../components';
import { Config } from '../config/config';

const ScheduleSection = styled.section`
  margin: 80px 0;
  .no--padding {
    padding: 0;
    margin: 0;
  }
  .mr--0 {
    margin-right: 0;
  }
`;

const TitleH1 = styled.h1`
  text-align: left;
  margin: 0 0 20px 0;
  font-size: 44px;
  color: #1AAE97;
  line-height: 44px;
  @media only screen and (max-width: 800px) {
		font-size: 30px;
    color: #333333;
	}
`;
const ScheduleLeftDiv = styled.div`
  float: left;
  width: 48%;
  background: white;
  div {
    padding: 22px 30px;
  }
  @media only screen and (max-width: 800px) {
		width: auto;
    float: none;
	}
`;
const FormLabel = styled.label`
  display: block;
  color: #1CAF98;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;
const FormInput = styled.input`
  display: block;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 10px;
  line-height: 30px;
  box-shadow: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #16AA94;
  &:focus, &:active {
    outline: none;
  }
`;
const FormSelect = styled.select`
  display: block;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  line-height: 30px;
  box-shadow: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #16AA94;
  &:focus, &:active {
    outline: none;
  }
`;
const FormTextArea = styled.textarea`
  display: block;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 10px;
  line-height: 30px;
  box-shadow: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #16AA94;
  &:focus, &:active {
    outline: none;
  }
`;
const FormButton = styled.input`
  display: block;
  width: 100%;
  border: none;
  background: #EFA507;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 55px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  cursor: pointer;
  color: white;
  font-weight: bold;
  &:hover {
    background: #FFB517;
  }
`;
const ScheduleRightDiv = styled.div`
  float: right;
  width: 48%;
  .mb-20 {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 800px) {
		width: auto;
    float: none;
	}
`;

const ContactRightDiv = styled.div`
float: right;
width: 48%;
margin-top: 40px;
min-height: 528px;
.mb-20 {
  margin-bottom: 20px;
}
#map_wrap {
  width: 100%;
  height: 230px;
  margin-top: -1px;
  background: white;
}
@media only screen and (max-width: 800px) {
  width: auto;
  float: none;
}

`;
const WrapDiv = styled.div`
  padding: 22px 30px;
  background: white;
  img {
    border-radius: 50%;
    float: left;
    margin-right: 30px;
  }
  h5 {
    font-size: 18px;
    margin: 10px 0;
    color: #333;
    font-weight: bold;
  }
  p {
    font-size: 16px;
    font-weight: 300;
    color: #333333;
    line-height: 23px;
  }
`;
const WrapP = styled.p`
  margin-right: 20px;
  margin-bottom: 30px;
`;

export const Schedule = () => {

  useEffect(() => {
    const uluru = { lat: 42.362360, lng: -88.511180 };
    // The map, centered at Uluru
    const map = new window.google.maps.Map(document.getElementById("map_wrap"), {
      zoom: 16,
      center: uluru,
    });
    // The marker, positioned at Uluru
    const marker = new window.google.maps.Marker({
      position: uluru,
      map: map,
    });
  }, [])

  const [formData, setFormData] = useState({
    'firstname': '',
    'lastname': '',
    'email': '',
    'telephone': '',
    'date': '',
    'time': '',
    'message': ''
  });
  const handleScheduleSubmit = (e) => {
    e.preventDefault();
    // setFormData({
    //   ...formData,
    //   [e.target.name]: e.target.value
    // });
    console.log(formData);
    // const url = "http://localhost:3005/tour";
    const url = 'https://api.vhnursing.com';
    // const data = { 
    //   firstname: formData.firstname, 
    //   lastname: formData.lastname, 
    //   email: formData.email,
    //   telephone: formData.telephone,
    //   date: formData.date,
    //   time: formData.time,
    //   message: formData.message
    // };
    if (Object.values(formData).includes('')) {
      return;
    }
    axios
      .post(url, formData)
      .then(res => {
        console.log("Res: ", res);
        alert("Sent Successfully");
        setFormData({
          ...formData,
          firstname: '',
          lastname: '',
          email: '',
          telephone: '',
          date: '',
          time: '',
          message: ''
        })
      })
      .catch(err => {
        console.log("Err: ", err);
      })
    // fetch(url, { method: 'POST',
    //   body: JSON.stringify(data), // data can be `string` or {object}!
    //   headers:{ 'Content-Type': 'application/json' } })
    // .then(res => res.json())
    // .catch(error => console.error('Error:', error))
    // .then(response => console.log('Success:', response)); 
  }

  const handleChange = e => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <ScheduleSection>
      <Page>
        <TitleH1>{Config["home"]["schedule_tour_button"]}</TitleH1>
        <ScheduleLeftDiv>
        <form onSubmit={handleScheduleSubmit}>
          <WrapDiv>
            <ScheduleLeftDiv className="no--padding">
              <WrapP>
                <FormLabel>FIRST NAME</FormLabel>
                <FormInput type='text' onChange={handleChange} name="firstname" placeholder="Your First Name"  />
              </WrapP>
            </ScheduleLeftDiv>
            <ScheduleRightDiv className="no--padding">
              <WrapP>
                <FormLabel>LAST NAME</FormLabel>
                <FormInput name="lastname" placeholder="Your Last Name" type='text' onChange={handleChange} />
              </WrapP>
            </ScheduleRightDiv>
            <Clear className="no--padding" />
            <WrapP>
              <FormLabel>EMAIL ADDRESS</FormLabel>
              <FormInput name="email" placeholder="Enter email" type='email' onChange={handleChange} />
            </WrapP>
            <WrapP>
              <FormLabel>TELEPHONE</FormLabel>
              <FormInput name="telephone" placeholder="(XXX) XXX-XXXX" type='tel' onChange={handleChange} />
            </WrapP>
            <WrapP>
              <FormLabel>PREFERRED DATE</FormLabel>
              <FormInput onChange={handleChange} name="date" placeholder="" type='date' />
            </WrapP>
            <WrapP className="mr--0">
              <FormLabel>PREFERRED TIME</FormLabel>
              <FormSelect name="time" value={formData.time} onChange={handleChange}>
                <option value="Morning">Morning</option>
                <option value="Afternoon">Afternoon</option>
                <option value="Evening">Evening</option>
              </FormSelect>
            </WrapP>
            <WrapP>
              <FormLabel>MESSAGE</FormLabel>
              <FormTextArea name="message" placeholder="Description" type='text' onChange={handleChange} />
            </WrapP>            
          </WrapDiv>
          <FormButton type="submit" value="Submit" />
        </form>
        </ScheduleLeftDiv>
        <ContactRightDiv>
          <WrapDiv className="mb-20">
            <img src="/assets/images/phone.jpg" alt="Phone" />
            <h5>Phone</h5>
            <p>Tel: {Config["contact"]["contact_phone_number"]}</p>
            <p>Fax: {Config["contact"]["contact_fax_number"]}</p>
            <Clear />
          </WrapDiv>
          <WrapDiv className="mb-20">
            <img src="/assets/images/phone.jpg" alt="Phone" />
            <h5>Email</h5>
            {/* <p>Tel: {Config["contact"]["contact_phone_number"]}</p> */}
            <p>Email: {Config["contact"]["contact_email"]}</p>
            <Clear />
          </WrapDiv>
          <div id="map_wrap"></div>
          <p style={{ paddingTop: '8px', textAlign: 'right', fontSize: '1em'  }}>2406 Hartland Rd, Woodstock IL 60098</p>
        </ContactRightDiv>
        <Clear />
      </Page>
    </ScheduleSection>
  )
};
