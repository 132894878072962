import React from "react";
import styled from "styled-components";
import { 
  Clear, Page, Articles, RightSidebar
} from '../components';
import { Config } from '../config/config';

const PostSection = styled.section`
  padding: 60px 0;
  background: white;
`;

const ImageDiv = styled.div`
  width: 100%;
  margin-bottom: 20px;
  img {
    width: 96%;
    height: auto;
  }
`;

const PostContentDiv = styled.div`
  float: left;
  width: 66%;

  @media only screen and (max-width: 800px) {
    float: none;
    width: auto;
  }
`;
const RightSidebarDiv = styled.div`
  float: right;
  width: 33%;
  @media only screen and (max-width: 800px) {
    float: none;
    width: auto;
    padding-top: 10px;
  }
`;
const RightSidebarH3 = styled.h3`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #1E998A;
  text-transform: uppercase;
`;
const PostTitleLink = styled.a`
  outline: none;
  text-decoration: none;
`;
const PostTitleH2 = styled.h2`
  font-size: 24px;
  color: #404D5F;
  line-height: 34px;
  cursor: pointer;
  margin: 0;
  text-transform: uppercase;
  &:hover {
    color: #15AC95;
  }

  @media only screen and (max-width: 800px) {
    text-align: center;
    font-size: 22px;
  }
`;

const AnchorWrap = styled.div`
  margin: 15px 0;
  span {
    color: #8595B1;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
  }
  a {
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    color: #404D5F;
    display: inline-block;
    margin-right: 12px;
    text-decoration: none;
    &:hover {
      color: #15AC95;
    }
  }
`;

export const DetailPost = () => (
  <PostSection>
    <Page>
      <PostContentDiv>
        <ImageDiv>
					<img src="/assets/images/tab1.png" alt="Right Sidebar" />
				</ImageDiv>
        <PostTitleLink>
          <PostTitleH2>{Config["news"]["post_title"]}</PostTitleH2>
        </PostTitleLink>

        <AnchorWrap>
          <span>BY: </span>
          <a href="#this" title="Username">Admin</a>
          <span>ON: </span>
          <a href="#this" title="Date">29 Jun 2019</a>
        </AnchorWrap>
        
        <Articles>{Config["news"]["post_content"]} <br /><br />
        </Articles>
      </PostContentDiv>
      <RightSidebarDiv>
        <RightSidebarH3>{Config["about"]["right_sidebar_title"]}</RightSidebarH3>
        <RightSidebar isBanner={true} />
      </RightSidebarDiv>
      <Clear />
    </Page>
  </PostSection>
);