import axios from "axios";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Clear, Page } from '../components';
import { Config } from '../config/config';
import { contactService } from "../utils/axios";

const ContactSection = styled.section`
  margin: 50px 0;
`;

const TitleH1 = styled.h1`
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 44px;
  color: #1AAE97;
  line-height: 44px;

  @media only screen and (max-width: 800px) {
		font-size: 30px;
    color: #333333;
    padding-top: 30px;
	}
`;
const SubTitleH5 = styled.h5`
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: #333333;
`;
const ContactLeftDiv = styled.div`
  float: left;
  width: 48%;
  margin-top: 40px;
  background: white;
  min-height: 528px;
  div {
    padding: 22px 30px;
  }

  @media only screen and (max-width: 800px) {
		width: auto;
    float: none;
	}
`;
const FormLabel = styled.label`
  display: block;
  color: #1CAF98;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;
const FormInput = styled.input`
  display: block;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 10px;
  line-height: 30px;
  box-shadow: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #16AA94;
  // &:focus, &:active {
  //   outline: none;
  // }
`;
const FormTextArea = styled.textarea`
  display: block;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 10px;
  line-height: 30px;
  box-shadow: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #16AA94;
  &:focus, &:active {
    outline: none;
  }
`;
const FormButton = styled.input`
  display: block;
  width: 100%;
  border: none;
  background: #EFA507;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 55px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  cursor: pointer;
  color: white;
  font-weight: bold;
  &:hover {
    background: #FFB517;
  }
`;
const ContactRightDiv = styled.div`
  float: right;
  width: 48%;
  margin-top: 40px;
  min-height: 528px;
  .mb-20 {
    margin-bottom: 20px;
  }
  #map_wrap {
    width: 100%;
    height: 230px;
    margin-top: -1px;
    background: white;
  }
  @media only screen and (max-width: 800px) {
		width: auto;
    float: none;
	}
`;
const WrapDiv = styled.div`
  padding: 22px 30px;
  background: white;
  img {
    border-radius: 50%;
    float: left;
    margin-right: 30px;
  }
  h5 {
    font-size: 18px;
    margin: 10px 0;
    color: #333;
    font-weight: bold;
  }
  p {
    font-size: 16px;
    font-weight: 300;
    color: #333333;
    line-height: 23px;
  }
`;
const WrapP = styled.p`
  margin-top: 30px;
  margin-right: 20px;
`;

export const Contact = () => {
  useEffect(() => {
      const uluru = { lat: 42.362360, lng: -88.511180 };
      // The map, centered at Uluru
      const map = new window.google.maps.Map(document.getElementById("map_wrap"), {
        zoom: 16,
        center: uluru,

      });
      // The marker, positioned at Uluru
      const marker = new window.google.maps.Marker({
        position: uluru,
        map: map,
      });
  }, []);
    const [contactFormData, setContactFormData] = useState({
      name: '',
      email: '',
      telephone: '',
      message: '',
    });
    const handleSubmit = async(e) => {
      e.preventDefault();
      setContactFormData({
        ...contactFormData,
        [e.target.name]: e.target.value
      });

      // const url = "https://apiserver.link/api";
      const data = {
        name: contactFormData.name,
        email: contactFormData.email,
        telephone: contactFormData.telephone,
        message: contactFormData.message
      };

      // const res = await axios.post("http://localhost:8787", JSON.stringify({}));
      // console.log("RES: ", res);
      // console.log("TEST");
      // let response = await contactService(data);
      // console.log("Response: ", response);

      try {
        // const res = await axios.post("localhost:3001/vhnursing/contact", {
          // headers: {
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': '*',
          // }
        // });
        //
        const res = await axios.get("http://localhost:3001");
        // const res = (await fetch("http://localhost:3001/vhnursing/contract", {
        //   body: data,
        //   method: "post"
        // })).json();
        console.log("RES: ", res);
      } catch (err) {
        console.log("ERROR: ", err);
      }
    }

    const handleChange = e => {
      e.preventDefault();

      setContactFormData({
        ...contactFormData,
        [e.target.name]: e.target.value
      })
    }

    return (
    <ContactSection>
      <Page>
        <TitleH1>{Config["contact"]["contact_block_title"]}</TitleH1>
        <SubTitleH5>{Config["contact"]["contact_block_subtitle"]}</SubTitleH5>
        <ContactLeftDiv>
          <form onSubmit={handleSubmit}>
          <WrapDiv>
            <WrapP>
              <FormLabel>NAME</FormLabel>
              <FormInput name="name" id="name" placeholder="Your Name" onChange={handleChange} value={contactFormData.name} />
            </WrapP>
            <WrapP>
              <FormLabel>EMAIL ADDRESS</FormLabel>
              <FormInput name="email" id="email" placeholder="Enter email" onChange={handleChange} value={contactFormData.email} />
            </WrapP>
            <WrapP>
              <FormLabel>TELEPHONE</FormLabel>
              <FormInput name="telephone" id="telephone" placeholder="(XXX) XXX-XXXX" onChange={handleChange} value={contactFormData.telephone} />
            </WrapP>
            <WrapP>
              <FormLabel>MESSAGE</FormLabel>
              <FormTextArea name="message" id="message" placeholder="Description" onChange={handleChange} value={contactFormData.message} />
            </WrapP>
          </WrapDiv>
          <FormButton type="submit" value="Submit" />
          </form>
        </ContactLeftDiv>
        <ContactRightDiv>
          <WrapDiv className="mb-20">
            <img src="/assets/images/phone.jpg" alt="Phone" />
            <h5>Phone</h5>
            <p>Tel: {Config["contact"]["contact_phone_number"]}</p>
            <p>Fax: {Config["contact"]["contact_fax_number"]}</p>
            <Clear />
          </WrapDiv>
          <WrapDiv className="mb-20">
            <img src="/assets/images/phone.jpg" alt="Phone" />
            <h5>Email</h5>
            {/* <p>Tel: {Config["contact"]["contact_phone_number"]}</p> */}
            <p>Email: {Config["contact"]["contact_email"]}</p>
            <Clear />
          </WrapDiv>
          <div id="map_wrap"></div>
          <p style={{ paddingTop: '8px', textAlign: 'right', fontSize: '1em'  }}>2406 Hartland Rd, Woodstock IL 60098</p>
        </ContactRightDiv>
        <Clear />
      </Page>
    </ContactSection>
    )
};
