import React from 'react';
import { ServicePost, Support } from '../sections';
import { Header, Footer } from '../layout';
import { Container, PageTitle } from '../components';
import { Config } from '../config/config';

export const ServicePage = () => {
	return (
		<Container>
			<Header />

			<PageTitle title={Config["services"]["services_page_title"]} />

			<ServicePost />
			{/* <Support /> */}
			
			<Footer />
		</Container>
	);
}