import React from 'react';
import styled from 'styled-components';

const FooterStyle = styled.div`
	font-family: 'Arial', sans-serif;
	width: 100%;
	background: #404D5F;
	text-align: center;
	padding: 25px 0;

	.info,
	.info a {
		color: #FFF;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 20px;
	}

	.info a:hover {
		cursor: pointer;
	}

	@media only screen and (max-width: 800px) {
		padding: 50px 0;
		.info {
			line-height: 40px;
		}
	}
`;

export const Footer = () => {
	return (
		<FooterStyle>
			<p className="info">
				&copy; Valley Hi 2023. All Rights Reserved. <a href="https://dirtroad.dev" target="_blank">Powered by Dirt Road Development</a>
			</p>
		</FooterStyle>
	);
}
