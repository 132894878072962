import React from 'react';
import { SubTitle, TourButton } from '../components';
import { HomeSlider, Category, Burgess, Facility, Testimonials } from '../sections';
import { Header, Footer } from '../layout';
import { Container, Page } from '../components/common';
import { Config } from '../config/config';

export const HomePage = () => {
	return (
		<Container>
			<Header />
			<HomeSlider />
			<Page>
				<SubTitle title={Config["home"]["tab_block_title"]} subtitle={Config["home"]["tab_block_subtitle"]} />
				<Category />
			</Page>
			<Burgess />
			<Facility />
			<Page>
				<Testimonials />
			</Page>
			
			<TourButton />
			<Footer />

		</Container>
	);
}