import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from '../config/config';

const StyledUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  top: 15px;
  right: 15px;

  @media only screen and (max-width: 980px) {
    width: 70%;
    text-align: right;
  }
  @media only screen and (max-width: 800px) {
    display: none;
    width: 100%;
    background: #EEB01B;
    position: absolute;
    top: 85px;
    border-top: 3px solid #15AC95;
    left: 0;
    z-index: 999;

    &.show_menu {
      display: block;
    }
  }
`;

const StyledLi = styled.li`
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;

  a {
    display: inline-block;
    color: #000;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    margin: 15px 10px;
    transition: all 0.3s ease-in-out;
    font-size: 1.25rem;
    &:hover {
      color: #43bba9;
    }
  }
  @media only screen and (max-width: 800px) {
    display: block;
    width: 100%;
    text-align: left;
    a {
      display: block;
      color: ${COLORS.White};
      width: 100%;
      margin: 0;
      font-size: 16px;
      text-align: left;
      border-bottom: 1px solid ${COLORS.White};
      padding: 20px 15px;
    }
  }
`;

const MobileMenuSpan = styled.label`
  display: none;
  margin: auto;
  color: ${COLORS.White};
  border-bottom: 0.1px solid ${COLORS.White};
  width: unset;
  cursor: pointer;
  padding-top: 18px;
  top: 4px;
  float: right;
  position: relative;

  span {
    display: block;
    position: absolute;
    width: 27px;
    height: 3px;
    border-radius: 30px;
    right: 0;
    margin-right: 15px;
    background: #43bba9;
    -webkit-transition: .2s ease;
    -ms-transition: .2s ease;
    -moz-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    top: 23px;

    &:first-child {
      top: 15px;
    }
    &:last-child {
      top: 31px;
    }
  }

  @media only screen and (max-width: 800px) {
    display: block;
  }
`;

/*
const Dropbtn = styled.div`
  display: inline-block;
  color: #8595b1;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  margin: 15px 10px;
  &::after {
	content: "\f107";
    color: #8595b1;
	margin-left: 10px;
    font: normal normal normal 16px/1 FontAwesome;
  }
`;

const DropDownContent = styled.div`
  display: none;
  position: absolute;
  min-width: 160px;
  background-color: #15ac95;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropDownLi = styled(StyledLi)`
  display: block;
  &:hover {
	transition: all 1s ease-in-out;
    color: #43bba9;
  }
  &:hover ${DropDownContent} {
	transition: all 1s ease-in-out;
    display: block;
  }
  a {
    text-decoration: none;
    display: block;
    text-align: left;
    margin: 0;
    float: none !important;
    padding: 15px;
    color: ${COLORS.White};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
    color: ${COLORS.White}
      background-color: #efa507;
    }
  }
`; */

export const NavBar = () => {
    return (
      <>
      <input type="checkbox" name="mobile_status" id="mobile-status-id" />
      <MobileMenuSpan htmlFor="mobile_status">
        <span></span>
        <span></span>
        <span></span>
      </MobileMenuSpan>

      <StyledUl id="mobile_menu">
        <StyledLi>
          <Link to="/">Home</Link>
        </StyledLi>
        <StyledLi>
          <Link to="/about">About Us</Link>
        </StyledLi>
		    <StyledLi>
          <Link to="/service">Services</Link>
        </StyledLi>
		    <StyledLi>
          <Link to="/news">News &amp; Events</Link>
        </StyledLi>
		    <StyledLi>
          <a href="https://www.mchenrycountyil.gov/employment" title="Careers" target="_blank" rel="noopener noreferrer">Careers</a>
        </StyledLi>
		    <StyledLi>
          <Link to="/contact">Contact</Link>
        </StyledLi>
        <StyledLi>
          <Link to='/admissions'>Admissions</Link>
        </StyledLi>
		    <StyledLi>
          <Link to="/schedule">Schedule a Tour</Link>
        </StyledLi>
      </StyledUl>
    </>
	);
}
