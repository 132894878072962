import React from 'react';
import { Schedule } from '../sections';
import { Header, Footer } from '../layout';
import { Container } from '../components';

export const SchedulePage = () => {
	return (
		<Container>
			<Header />

			<Schedule />

			<Footer />
		</Container>
	);
}