import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { 
  Clear, Page, Articles, RightSidebar, PrimaryButton, RightSidebarNews
} from '../components';
import { Config, COLORS } from '../config/config';
import { News } from '../config/news';

const PostSection = styled.section`
  padding: 60px 0;
  background: white;
`;

const PostContentDiv = styled.div`
  float: left;
  width: 66%;

  @media only screen and (max-width: 800px) {
    float: none;
    width: auto;
  }
`;
const RightSidebarDiv = styled.div`
  float: right;
  width: 33%;
  @media only screen and (max-width: 800px) {
    float: none;
    width: auto;
    padding-top: 10px;
  }
`;
const RightSidebarH3 = styled.h3`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #1E998A;
  text-transform: uppercase;
`;
const PostTitleLink = styled.a`
  outline: none;
  text-decoration: none;
`;
const PostTitleH2 = styled.h2`
  font-size: 24px;
  color: #404D5F;
  line-height: 34px;
  cursor: pointer;
  margin: 0;
  text-transform: uppercase;
  &:hover {
    color: #15AC95;
  }

  @media only screen and (max-width: 800px) {
    text-align: center;
    font-size: 22px;
  }
`;

const AnchorWrap = styled.div`
  margin: 15px 0;
  span {
    color: #8595B1;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
  }
  p {
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    color: #404D5F;
    display: inline-block;
    margin-right: 12px;
    text-decoration: none;
    &:hover {
      color: #15AC95;
    }
  }
`;

const ArticleButton = styled.button`
  padding: 15px 25px;
  background: ${COLORS.Green};
  color: ${COLORS.White};
  border: none;

`;

export const NewsPosts = () => {
  
  const [selectedContent, setSelectedContent] = useState(-1);

  const displayContent = (content, index) => {
    const isDisplayed = index === selectedContent;

    return isDisplayed ? content : content.substr(0, content.length > 150 ? 150 : content.length);
  }

  useEffect(() => {
    console.log("Selected: ", selectedContent);
  }, [selectedContent])

  // const showDetails = e => {
  //   e.preventDefault();
  //   console.log("E.Target: ", e.target);
  //   setSelectedContent(e.target.value);
  // }

  return (
      <PostSection>
        <Page>
          {News.articles && News.articles.length > 0 ? News.articles.map((article, index) => {
            return (
              <PostContentDiv>
                <PostTitleLink>
                  <PostTitleH2>{article.title}</PostTitleH2>
                </PostTitleLink>
                <AnchorWrap>
                  <span>Written By: </span>
                  <p title="Username">{article.by}</p>
                  <span>Date: </span>
                  <p title="Date">{article.date}</p>
                </AnchorWrap>
                {article.content.length > 150 ? <Articles>{displayContent(article.content, index)} <br /><br />
                  {selectedContent !== index ? <ArticleButton value={index} onClick={(e) => {
                    e.preventDefault();
                    console.log("E.Target: ", e.target.value);
                    setSelectedContent(index);
                  }}>READ MORE</ArticleButton> : <ArticleButton value={index} onClick={(e) => {
                    e.preventDefault();
                    setSelectedContent(-1);
                  }}>Close Article</ArticleButton>}
                </Articles> : <Articles>{article.content}</Articles>}
                <br />
                <br />
              </PostContentDiv>
            );
          }) : <p>Sorry, no new articles have been posted. Check back later!</p>}
          <RightSidebarDiv>
            <RightSidebarH3>Pinned</RightSidebarH3>
            <RightSidebarNews />
          </RightSidebarDiv>
          <Clear />
        </Page>
      </PostSection>
    );
};