import React from 'react';
import { NewsPosts } from '../sections';
import { Header, Footer } from '../layout';
import { Container, PageTitle } from '../components';

export const NewsPage = () => {
	return (
		<Container>
			<Header />

			<PageTitle title="News And Events" />

			<NewsPosts />
			
			<Footer />
		</Container>
	);
}