import React from 'react';
import styled from 'styled-components';

const HeaderImage = styled.div`
	width: 100%;
    img {
        width: 100%;
        height: auto;
    }
`;
 
export const HomeSlider = () => (
    <HeaderImage>
        <img src="/assets/images/building.jpg" alt="Header Banner" />
    </HeaderImage>
);