import "./App.css";
import { 
  HomePage, AboutPage, ContactPage, DetailPage,
  SchedulePage, NewsPage, ServicePage, AdmissionsPage
} from './pages';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <main className='App'>
      <Router>
        <Switch>
          {/*<Route component={NavigationBar} />*/}
          <Route exact path='/' component={HomePage} />
          <Route exact path='/about' component={AboutPage} />
          <Route exact path='/admissions' component={AdmissionsPage} />
          <Route exact path='/contact' component={ContactPage} />
          <Route exact path='/detail' component={DetailPage} />
          <Route exact path='/news' component={NewsPage} />
          <Route exact path='/schedule' component={SchedulePage} />
          <Route exact path='/service' component={ServicePage} />
          <Route exact path='/service/:id' component={ServicePage} />
        </Switch>
      </Router>
    </main>
  );
}

export default App;
