import React from "react";
import styled from "styled-components";
import { Clear, Page, SubTitle, Articles, RightSidebar } from '../components';
import { Config } from '../config/config';
import ReactPlayer from 'react-player';

const AwardsSection = styled.section`
  padding: 1px 0 60px;
  background: white;
`;

const PhotosSection = styled.section`
  padding: 1px 0 60px;

`;


const AwardsWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: space-evenly;
    flex-wrap: wrap;
    img {
        height: 350px;
        width: calc(95% / ${props => props.items});
        margin: 0 4px;
    }
    max-width: 100vw;
    @media only screen and (max-width: 864px) {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        border-radius: 16px !important;
        img {
            margin: 4px 0;
            height: 30vh;
            width: 85%;
            marign: 0 auto;
        }
    }
`;

const ExperienceSection = styled.section`
  width: 100%;
  padding: 50px 0;
  background: #ebb579;
  .text-center {
    text-align: center;
  }
  @media only screen and (max-width: 800px) {
		width: auto;
    padding: 30px 20px;
	}
`;

const ExperienceWrap = styled.div`
  width: 66%;
  h2 {
    color: #404D5F;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;
    margin: 0 0 30px;
    text-transform: uppercase;
  }My stay here was nice.  It’s my third time here and I enjoy the conversation with the nice people here. It’s fun, I liked the new suites. It was clean and the food was so good I think I gained 10lbs! Therapy was fantastic and helpful
  p {
    font-size: 16px;
    color: black;
    font-weight: normal;
    line-height: 23px;
    margin-bottom: 35px;
    text-align: justify;
  }
  h4 {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: #404D5F;
  }
My stay here was nice.  It’s my third time here and I enjoy the conversation with the nice people here. It’s fun, I liked the new suites. It was clean and the food was so good I think I gained 10lbs! Therapy was fantastic and helpful
  @media only screen and (max-width: 800px) {
    display: block;
    width: auto;
  }
`;

const WhoWeAreSection = styled.section`
  width: 100%;
  padding: 1px 0 50px;
`;

const PostContentDiv = styled.div`
  float: left;
  width: 100%;

  @media only screen and (max-width: 800px) {
    float: none;
    width: auto;
  }
`;


const YoutubeVideoContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const YoutubeSubtitle = styled.p`
	font-style: italic;
	font-size: 0.75rem;
`;

export const YoutubeVideo = ({ url }) => {
  return (
    <YoutubeVideoContainer>
      <ReactPlayer url={url} />
	  <YoutubeSubtitle>Valley Hi Welcome Video</YoutubeSubtitle>
    </YoutubeVideoContainer>
  )
}

export const WhoWeAre = () => (
  <WhoWeAreSection>
    <Page>
      <PostContentDiv>
        <SubTitle title={Config["about"]["about_block_title"]} subtitle={Config["about"]["about_block_subtitle"]}align="left" />
        <Articles>{Config["about"]["core"]}</Articles>
        <Articles>
          <YoutubeVideo url="https://www.youtube.com/watch?v=Bn1Lehgbhh8" />
        </Articles>
      </PostContentDiv>
      <Clear />
    </Page>
  </WhoWeAreSection>
);

export const Experiences = () => (
  <ExperienceSection>
    <Page>
      <ExperienceWrap>
        <h2>{Config["about"]["about_experience_title"]}</h2>
        <p>{Config["about"]["about_experience_content"]}</p>

        <h4 className="text-center">{Config["about"]["about_experience_bottom_subtitle"]}</h4>
        <h2 className="text-center">{Config["about"]["about_experience_bottom_title"]}</h2>
      </ExperienceWrap>
    </Page>
  </ExperienceSection>
);

export const Awards = () => (
    <AwardsSection>
      <Page>
        <SubTitle title={Config["about"]["about_award_title"]} className="mt-0"/>
        <br />
        <AwardsWrap items={2}>
            {[1, 2,3,4,5,6,7,8,9].map((val) => {
                return (
                    <>
                        <img style={{ margin: '16px 4px', width: "35%", height: "auto" }} src={Config["about"][`about_award_logo${val}`]} alt={val} />
                    </>
                );
            })}
        </AwardsWrap>
      </Page>
    </AwardsSection>
);

export const MedicareSuites = () => (
  <PhotosSection>
    <Page>
      <SubTitle title="Medicare Suites" className="mt-0" />
      <br />
      <AwardsWrap items={2}>
          <img src={Config["about"]["medicare_1"]} alt={Config["about"]["medicare_1_alt"]} />
          <img src={Config["about"]["medicare_2"]} alt={Config["about"]["medicare_2_alt"]} />
        </AwardsWrap>
    </Page>
  </PhotosSection>
)

export const ShowerRoom = () => (
  <PhotosSection>
    <Page>
      <SubTitle title="Shower Rooms" className="mt-0" />
      <br />
      <AwardsWrap items={3}>
          <img src={Config["about"]["shower_room_1"]} alt={Config["about"]["shower_room_1_alt"]} />
          <img src={Config["about"]["shower_room_2"]} alt={Config["about"]["shower_room_2_alt"]} />
          <img src={Config["about"]["shower_room_3"]} alt={Config["about"]["shower_room_3_alt"]} />
        </AwardsWrap>
    </Page>
  </PhotosSection>
);

export const TherapyGym = () => {
    return (
        <PhotosSection>
            <Page>
                <SubTitle title="NEW Therapy Gym" className="mt-0" />
                <br />
                <AwardsWrap items={3}>
                    {[1, 2, 3, 4, 5, 6].map((val) => {
                        return (
                            <>
                                <img style={{ margin: '4px 0' }} src={Config["about"][`therapy_room_${val}`]} alt={`Therapy Room ${val}`} />
                            </>
                        );
                    })}
                </AwardsWrap>
            </Page>
        </PhotosSection>
    );
}
