import React from 'react';
import styled from 'styled-components';
import { Header, Footer } from '../layout';
import { Container, PageTitle, Articles } from '../components';
import { Link } from 'react-router-dom';
import { YoutubeVideo } from '../sections';

const AdmissionSections = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
`;

const AdmissionSection = styled.div`
    width: 100%;
    min-height: 42.5vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 0.5px solid #85C35E;
    &:nth-child(odd) {
        background: silver;
    }
    &:nth-child(1) {
        h3 {
            color: #26ABE1;
        }
    }
    &:nth-child(2) {
        h3 {
            color: #EF322E;
        }
    }
    &:nth-child(4) {
        h3 {
            color: #85C35E;
        }
    }
    &:nth-child(3) {
        h3 {
            color: #F9D93E;
        }
    }
`;
const AdmissionTitle = styled.h3`
    text-align: center;
    font-size: 1.75rem;
    font-weight: bold;
    padding: 16px;
`;
const AdmissionContent = styled.p`
    text-align: center;
    font-weight: normal;
    font-size: 1.25rem;
    width: 85%;
    margin: 0 auto;
`;
const AdmissionButton = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
    a {
        padding: 16px;
        background: lightgrey;
        color: black;
        text-decoration: none;
        border-radius: 7.5px;
    }
`;

const AdmissionLink = styled.a``;

export const AdmissionsPage = () => {

    const showVideo = e => {
        e.preventDefault();
        
    }

    return (
        <Container>
            <Header />
            <PageTitle title="Admissions" />
            <AdmissionSections>
                <AdmissionSection>
                    <AdmissionTitle>Step 1 | Virtual Tour</AdmissionTitle>
                    <AdmissionContent>
                        Are you interested in Valley Hi for yourself or a loved one? Start by watching the quick virtual tour that will take you around the grounds.
                    </AdmissionContent>
                    <AdmissionButton>
                    <Articles>
                        <YoutubeVideo url="https://www.youtube.com/watch?v=Bn1Lehgbhh8" />
                    </Articles>
                    </AdmissionButton>
                </AdmissionSection>
                <AdmissionSection>
                    <AdmissionTitle>Step 2 | Contact Admissions</AdmissionTitle>
                    <AdmissionContent>
                    Love what you see? Contact admissions by emailing <a href="mailto:valleyhi@mchenrycountyil.gov">valleyhi@mchenrycountyil.gov</a>
                        {/* Love what you see? Contact admissions by emailing <a href="mailto:valleyhi@mchenrycountyil.gov">valleyhi@mchenrycountyil.gov</a> and then fill out the admissions application <a href="">here.</a> */}
                    </AdmissionContent>
                </AdmissionSection>
                <AdmissionSection>
                    <AdmissionTitle>Step 3 | Tour Valley Hi</AdmissionTitle>
                    <AdmissionContent>
                        The next step is to setup a tour of Valley Hi. Please go <Link to='/schedule'>here </Link> to schedule a tour.
                    </AdmissionContent>
                </AdmissionSection>
                <AdmissionSection>
                    <AdmissionTitle>Step 4 | Medicaid Application</AdmissionTitle>
                    <AdmissionContent>
                    Before filling out the application, please call the business office at <a style={{ color: '#85C35E' }}href='tel:+18153342804'>815-334-2804</a> for assistance.
                        {/* Before filling out the application, please call <a href='tel:+18153342804'>815-334-2804</a> to talk about the application. Then, fill out the medicaid application <a href="">here.</a> */}
                    </AdmissionContent>
                </AdmissionSection>
                <AdmissionSection>
                    <AdmissionTitle>Step 5 | Welcome to Valley Hi</AdmissionTitle>
                    <AdmissionContent>
                        Thank you for completing the admissions process for Valley Hi Nursing &amp; Rehabilitation. We will get back to you as soon as possible with a decision. Thank you for choosing Valley Hi!
                    </AdmissionContent>
                </AdmissionSection>
            </AdmissionSections>
            <Footer />
        </Container>
    );
}

/**
 *    Have a link to the admissions application
    o   Have a link to virtual tour
    o   Have link to Medicaid application online

 */