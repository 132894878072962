import React from 'react';
import { Contact } from '../sections';
import { Header, Footer } from '../layout';
import { Container, PageTitle } from '../components';
import { Config } from '../config/config';

export const ContactPage = () => {
	return (
		<Container>
			<Header />

			<PageTitle title={Config["contact"]["contact_page_title"]} />

			<Contact />

			<Footer />
		</Container>
	);
}