import React from 'react';
import styled from 'styled-components';

const PostDiv = styled.div`
	padding: 30px 15px;
	text-align: left;
	
	.quote {
		padding: 33px;
		line-height: 30px;
		font-size: 18px;
		background: #F9D93E;
		color: black;
		font-weight: 100;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			border-top: 0;
			width: 0;
			left: 45px;
			bottom: -16px;
			border-left: 17px solid #EFA507;
			border-top: none;
			border-bottom: 20px solid transparent;
		}
	}

	.custom-value {
		margin: 35px 0 0 10px;
		text-transform: uppercase;
		color: #404D5F;
		line-height: 20px;
		font-size: 17px;
	}
`;

export const PostText = ({ name, children }) => {

	return (
		<PostDiv>
			<p className="quote">{children}</p>
			<p className="custom-value">{name}</p>
		</PostDiv>
	);

}