import React from "react";
import styled from "styled-components";
import { 
  Clear, Page,SubTitle
} from '../components';
import { Config } from '../config/config';

const PostSection = styled.section`
  padding: 60px 0;
  background: white;
`;

const PostContentDiv = styled.div`
  float: left;
  width: 100%;

  @media only screen and (max-width: 800px) {
    float: none;
    width: auto;
  }
`;

const SupportSection = styled.section`
  width: 100%;
  padding: 50px 0;
  background: #ebb579;
  .text-center {
    text-align: center;
  }
  @media only screen and (max-width: 800px) {
		width: auto;
    padding: 30px 20px;
	}
`;

const SupportWrap = styled.div`
  width: 66%;
  h2 {
    color: #404D5F;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;
    margin: 0 0 30px;
    text-transform: uppercase;
  }
  p {
    font-size: 16px;
    color: black;
    font-weight: normal;
    line-height: 23px;
    margin-bottom: 35px;
    text-align: justify;
  }
  h4 {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: #404D5F;
  }

  @media only screen and (max-width: 800px) {
    display: block;
    width: auto;
  }
`;

const LocalServiceContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 150px;
  padding: 10px 0;
  border-bottom: 0.5px solid grey;
`;

const LocalServiceHeader = styled.h2`

`;
const LocalServiceContent = styled.p`
  line-height: 2;
`;
export const ServicePost = () => {

  return (
    <PostSection>
      <Page>
        <PostContentDiv>
          {/* <PostTitleLink> */}
            {/* <PostTitleH2>{Config["services"]["services_page_title"]}</PostTitleH2> */}
          {/* </PostTitleLink> */}
          {/* <Articles>{Config["services"]["services_page_content"]}<br /><br /></Articles> */}
          {Config['services'].sections && Config['services'].sections.map((service, index) => {
            return (
              <LocalServiceContainer id={`${index}`}>
                <LocalServiceHeader>{service.name}</LocalServiceHeader>
                <LocalServiceContent>{service.content}</LocalServiceContent>
              </LocalServiceContainer>
            );
          })}
        </PostContentDiv>
        {/* <RightSidebarDiv>
          <RightSidebarH3>{Config["about"]["right_sidebar_title"]}</RightSidebarH3>
          <RightSidebar isBanner={false} />
        </RightSidebarDiv> */}
        <Clear />
      </Page>
    </PostSection>
  );
}

export const Support = () => (
  <SupportSection>
    <Page>
      <SupportWrap>
        <SubTitle title={Config["services"]["services_next_title"]} align="left" subcolor="#FFFFFF" />
        <p>{Config["services"]["services_next_content"]}</p>
      </SupportWrap>
    </Page>
  </SupportSection>
);