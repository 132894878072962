import React from 'react';
import styled from 'styled-components';

const TitleSection = styled.section`
	width: 100%;
	background: #85C35E;
	padding: 70px 0;
	text-align: center;
	position: relative;
	h1 {
		color: white;
		margin: 0;
		text-align: center;
		font-size: 27px;
		font-weight: bold;
		line-height: 36px;
		text-transform: uppercase;
	}
`;

export const PageTitle = ({title}) => {

	return (
		<TitleSection>
			<h1>{title}</h1>
		</TitleSection>
	);

}