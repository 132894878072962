export const News = {
    "pinned": [
        {
            "name": "This Month's Activity Calendar",
            "link": "/assets/news/activity_calendar.jpg"
        }
    ],
    "articles": [
        {
            "by": "Thomas Annarella, LNHA",
            "date": "12/21/2021",
            "title": "Welcome to Valley Hi",
            "content": `Dear Family and Friends of Valley Hi:

            Welcome to Valley Hi. We hope that you find the new Valley Hi website helpful in obtaining information and forms that you may need. Feel free to print them out.

            At Valley Hi, we provide a wide range of services including long-term placement, short-term rehabilitation programs, and Hospice programming. We can care for your loved one when they need intermediate or skilled nursing care or have a need for rehab services to get back on their feet and back home in the community. We are able to provide care for residents who need or have Medicaid assistance, Medicare, and some insurance carriers.

            We provide many services and amenities to our residents including dining, daily activities, a beauty shop, a computer lab, an ice cream parlor, a chapel, and many more.  Our care is based on our Core Values of Quality, Compassion, Commitment, and Teamwork – all making Valley Hi Home.

            If you have any questions or would like additional information on the wonderful, cutting edge services offered at Valley Hi, please do not hesitate to contact us. We can schedule a tour for you to come see our beautiful facility and meet with us.  We will be continuously updating our website so please let us know what you would like to see.

            We look forward to seeing you soon.

            Thomas Annarella, LNHA
            Administrator`
        }
    ]
}
