import React from 'react';
import { Experiences, Awards, WhoWeAre, Photos, MedicareSuites, ShowerRoom, TherapyGym } from '../sections';
import { Header, Footer } from '../layout';
import { Container, PageTitle } from '../components';

export const AboutPage = () => {
	return (
		<Container>
			<Header />
			<PageTitle title="About Us" />
			<WhoWeAre />
			<MedicareSuites />
			<ShowerRoom />
            <TherapyGym />
			<Awards />
			<Footer />
		</Container>
	);
}
